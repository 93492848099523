import { HeaderGrid } from './General.js'

const BW_ERROR = {
    INSUFFICIENT_FUND: "Fondos insuficientes en la cuenta",
    UNAUTHORIZED_CARD: "Tarjeta inactiva o tarjeta no autorizada para transacciones sin tarjeta presente.",
  }

const PAYMENT_DICTIONARY = {
  "201": [
     {
        "status": "AUTHORIZED",
        "reason": "N/A",
        "message": "Successful transaction.\n",
        "message_es": "Transaccion exitosa. \n",
        "action": "N/A"
     },
     {
        "status": "PARTIAL_AUTHORIZED",
        "reason": "N/A",
        "message": "Partial amount was approved.\n",
        "message_es": "Importe parcial fue autorizado. \n",
        "action": "N/A"
     },
     {
        "status": "AUTHORIZED_PENDING_REVIEW",
        "reason": "AVS_FAILED",
        "message": " The issuing bank has questions about the request. You do not receive an authorization code\nprogrammatically, but you might receive one verbally by calling the processor.\n",
        "message_es": "La solicitud de autorización fue aprobada por el banco emisor,\n pero CyberSource la rechazó porque no pasó la verificación del \n Servicio de Verificación de Dirección (AVS).",
        "action": "You can capture the authorization, but consider reviewing the order for the possibility of fraud.\n"
     },
     {
        "status": "AUTHORIZED_PENDING_REVIEW",
        "reason": "CONTACT_PROCESSOR",
        "message": "The issuing bank has questions about the request. You do not receive an authorization code\nprogrammatically, but you might receive one verbally by calling the processor.\n",
        "message_es": "El banco emisor tiene preguntas sobre la solicitud. No recibe un código de autorización\nmediante programación, pero puede recibir uno verbalmente llamando al procesador.\n        ",
        "action": "Call your processor to possibly receive a verbal authorization. For contact phone numbers, refer to your\nmerchant bank information.\n"
     },
     {
        "status": "DECLINED",
        "reason": "EXPIRED_CARD",
        "message": "Expired card. You might also receive this if the expiration date you provided does not match the date the\nissuing bank has on file.\n\nNote: The ccCreditService does not check the expiration date; instead, it passes the request to the payment\nprocessor. If the payment processor allows issuance of credits to expired cards, CyberSource does not limit this\nfunctionality.\n",
        "message_es": "Tarjeta expirada. También puede recibir esto si la fecha de vencimiento que proporcionó no coincide con la fecha que tiene registrado el banco emisor.",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "PROCESSOR_DECLINED",
        "message": "General decline of the card. No other information provided by the issuing bank.\n",
        "message_es": "Declinación general de la tarjeta. No hay otra información proporcionada por el banco emisor.\n",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "PROCESSOR_ERROR",
        "message": "Transacción no autorizada: retirar tarjeta\n",
        "message_es": "Mensaje N/D",
        "action": "Refer the transaction to your customer support center for manual review.\n"
     },
     {
        "status": "DECLINED",
        "reason": "INSUFFICIENT_FUNDS",
        "message": "Insufficient funds in the account.\n",
        "message_es": "Fondos insuficientes en la cuenta.",
        "action": "Request a different card or other form of payment.\n"
     },
     {
         "status": "DECLINED",
         "reason": "INSUFFICIENT_FUND",
         "message": "Insufficient funds in the account.\n",
         "message_es": "Fondos insuficientes en la cuenta.",
         "action": "Request a different card or other form of payment.\n"
      },
     {
        "status": "DECLINED",
        "reason": "STOLEN_LOST_CARD",
        "message": "Stolen or lost card.\n",
        "message_es": "Tarjeta robada o extraviada.",
        "action": "Refer the transaction to your customer support center for manual review.\n"
     },
     {
        "status": "DECLINED",
        "reason": "ISSUER_UNAVAILABLE",
        "message": "Issuing bank unavailable.\n",
        "message_es": "Banco emisor no disponible.",
        "action": "Wait a few minutes and resend the request.\n"
     },
     {
        "status": "DECLINED",
        "reason": "UNAUTHORIZED_CARD",
        "message": "Inactive card or card not authorized for card-not-present transactions.\n",
        "message_es": "Tarjeta inactiva o tarjeta no autorizada para transacciones sin tarjeta presente.\n",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "CVN_NOT_MATCH",
        "message": "Card verification number (CVN) did not match.\n",
        "message_es": "El número de verificación de la tarjeta (CVN) no coincide.\n",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "EXCEEDS_CREDIT_LIMIT",
        "message": "The card has reached the credit limit.\n",
        "message_es": "La tarjeta ha alcanzado el límite de crédito.\n",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "INVALID_CVN",
        "message": "Invalid Card Verification Number (CVN).\n",
        "message_es": "Número de verificación de tarjeta (CVN) no válido.",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "DECLINED_CHECK",
        "message": "Generic Decline.\n",
        "message_es": "Declinacion generica, no hay mensaje",
        "action": "Request a different form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "BLACKLISTED_CUSTOMER",
        "message": "For eCheck payment, the customer matched an entry on the processor's negative file.\n",
        "message_es": "Para el pago con eCheck, el cliente hizo coincidir una entrada en el archivo negativo del procesador.\n",
        "action": "Review the order and contact the payment processor.\n"
     },
     {
        "status": "DECLINED",
        "reason": "SUSPENDED_ACCOUNT",
        "message": "For eCheck payment, Customer's account is frozen.\n",
        "message_es": "Para el pago con eCheck, la cuenta del Cliente está congelada.",
        "action": "Review the order or request a different form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "PAYMENT_REFUSED",
        "message": "Decline - (A) Merchant account or payer’s account is not set up to process such transactions. (B) Insufficient\nfunds in the payer’s funding source associated with the account, or transaction declined by bank. (C) A\nparticular action is not permitted, for example: capture refused, or the authorization has already been\ncaptured. (D) Fraud setting for the seller is blocking such payments.\n",
        "message_es": "Rechazo: (A) La cuenta del comerciante o la cuenta del pagador no está configurada para procesar tales transacciones. (B) Fondos\nsuficientes en la fuente de financiación del pagador asociada con la cuenta, o transacción rechazada por el banco. (C) No se permite una acción en particular, por ejemplo: captura denegada, o la autorización ya ha sido capturada. (D) La configuración de fraude para el vendedor está bloqueando dichos pagos.\n",
        "action": "Try a different payment method or a different account.\n"
     },
     {
        "status": "AUTHORIZED_PENDING_REVIEW",
        "reason": "CV_FAILED",
        "message": "The authorization request was approved by the issuing bank but declined by CyberSource because it\ndid not pass the card verification number (CVN) check.\n",
        "message_es": "La solicitud de autorización fue aprobada por el banco emisor, pero CyberSource la rechazó porque no pasó la verificación del número de verificación de la tarjeta (CVN).\n",
        "action": "You can capture the authorization, but consider reviewing the order for the possibility of fraud.\n"
     },
     {
        "status": "DECLINED",
        "reason": "INVALID_ACCOUNT",
        "message": "Invalid account number.\n",
        "message_es": "Numero de cuenta invalido",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "DECLINED",
        "reason": "GENERAL_DECLINE",
        "message": "General decline by the processor.\n",
        "message_es": "Declinacion general del procesador",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "INVALID_MERCHANT_CONFIGURATION",
        "message": "There is a problem with your CyberSource merchant configuration.\n",
        "message_es": "Existe un problema con tu configuracion de Cybersource",
        "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
     },
     {
        "status": "DECLINED",
        "reason": "BOLETO_DECLINED",
        "message": "The boleto request was declined by your processor.\n",
        "message_es": "La solicitud de 'boleto' fue declinada por tu procesaodr",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "SERVER_ERROR",
        "reason": "PROCESSOR_TIMEOUT",
        "message": "The request was received, but there was a timeout at the payment processor.\n",
        "message_es": "La solicitud fue recibida, pero se acabo el tiempo de espera para el pago del procesador ",
        "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center.\n"
     },
     {
        "status": "DECLINED",
        "reason": "DEBIT_CARD_USAGE_LIMIT_EXCEEDED",
        "message": "The Pinless Debit card's use frequency or maximum amount per use has been exceeded.\n",
        "message_es": "Se ha excedido la frecuencia de uso de la tarjeta de Débito Pinless o el monto máximo por uso.",
        "action": "Request a different card or other form of payment.\n"
     },
     {
        "status": "AUTHORIZED_RISK_DECLINED",
        "reason": "SCORE_EXCEEDS_THRESHOLD",
        "message": "Soft Decline - Fraud score exceeds threshold.\n",
        "message_es": "Rechazo suave: la puntuación de fraude supera el umbral.",
        "action": "Request or retry an authorization later.\n"
     },
     {
        "status": "PENDING_AUTHENTICATION",
        "reason": "CONSUMER_AUTHENTICATION_REQUIRED",
        "message": "The cardholder is enrolled in [[payerAuthentication]]. Please authenticate the cardholder before continuing with the transaction.\n",
        "message_es": "El titular de la tarjeta está inscrito en [[payerAuthentication]]. Autentique al titular de la tarjeta antes de continuar con la transacción.\n",
        "action": "Request or retry an authorization later.\n"
     },
     {
        "status": "DECLINED",
        "reason": "CONSUMER_AUTHENTICATION_FAILED",
        "message": "Encountered a [[payerAuthentication]] problem. Payer could not be authenticated.\n",
        "message_es": "Encontró un problema de [[payerAuthentication]]. No se pudo autenticar al pagador.\n",
        "action": "Request or retry an authorization later.\n"
        
     },
     {
        "status": "AUTHORIZED_PENDING_REVIEW",
        "reason": "DECISION_PROFILE_REVIEW",
        "message": "The order is marked for review by [[decisionManager]]\n",
        "message_es": "El pedido está marcado para revisión por [[decisionManager]]\n",
        "action": "Request or retry an authorization later.\n"
     },
     {
        "status": "AUTHORIZED_RISK_DECLINED",
        "reason": "DECISION_PROFILE_REJECT",
        "message": "The order has been rejected by [[decisionManager]]\n",
        "message_es": "El pedido ha sido rechazado por [[decisionManager]]",
        "action": "Request or retry an authorization later.\n"
     }
  ],
  "400": [
     {
        "status": "INVALID_REQUEST",
        "reason": "MISSING_FIELD",
        "message": "The request is missing one or more fields.\n",
        "action": "See the reply fields statusInformation.details[] for which fields are missing. Resend the request with the\ncorrect information.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "INVALID_DATA",
        "message": "One or more fields in the request contains invalid data.\n",
        "action": "See the reply fields statusInformation.details[] for which fields are invalid. Resend the request with the\ncorrect information.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "DUPLICATE_REQUEST",
        "message": "The merchantReferenceCode sent with this authorization request matches the merchantReferenceCode of another\nauthorization request that you sent in the last 15 minutes.\n",
        "action": "Resend the request with a unique merchantReferenceCode value.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "CARD_TYPE_NOT_ACCEPTED",
        "message": "The card type is not accepted by the payment processor.\n",
        "action": "Contact your merchant bank to confirm that your account is set up to receive the card in question.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "PROCESSOR_UNAVAILABLE",
        "message": "Processor failure.\n",
        "action": "Wait a few minutes and resend the request.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "INVALID_AMOUNT",
        "message": "The requested transaction amount must match the previous transaction amount.\n",
        "action": "Correct the amount and resend the request.\n"
     },
     {
        "status": "INVALID_REQUEST",
        "reason": "INVALID_CARD_TYPE",
        "message": "The card type sent is invalid or does not correlate with the credit card number.\n",
        "action": "Confirm that the card type correlates with the credit card number specified in the request, then resend the\nrequest.\n"
     }
  ],
  "502": [
     {
        "status": "SERVER_ERROR",
        "reason": "SYSTEM_ERROR",
        "message": "General system failure.\n",
        "action": "See the documentation for your CyberSource client (SDK) for information about how to handle retries in the case\nof system errors.\n"
     },
     {
        "status": "SERVER_ERROR",
        "reason": "SERVER_TIMEOUT",
        "message": "The request was received but there was a server timeout. This error does not include timeouts between the client\nand the server.\n",
        "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Business Center. See the documentation for your CyberSource client (SDK) for information about how to\nhandle retries in the case of system errors.\n"
     },
     {
        "status": "SERVER_ERROR",
        "reason": "SERVICE_TIMEOUT",
        "message": "The request was received, but a service did not finish running in time.\n",
        "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center. See the documentation for your CyberSource client (SDK) for information about\nhow to handle retries in the case of system errors.\n"
     },
     {
        "status": "SERVER_ERROR",
        "reason": "INVALID_OR_MISSING_CONFIG",
        "message": "Error - Invalid or missing merchant configuration\n",
        "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
     }
  ]
}

const PAYMENT_DICTIONARY_CAPTURE = {
   "201": [
       
         {
            "status": "PENDING",
            "reason": "N/A",
            "message": "Successful transaction.\n",
            "message_es": "Transaccion exitosa. \n",
            "action": "N/A"
         },
         {
            "status": "INVALID_REQUEST",
            "reason": "INVALID_MERCHANT_CONFIGURATION",
            "message": "There is a problem with your CyberSource merchant configuration.\n",
            "message_es": "Hay un problema con la configuración de comerciante de CyberSource. \n",
            "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
         },
         {
            "status": "SERVER_ERROR",
            "reason": "PROCESSOR_TIMEOUT",
            "message": "The request was received, but there was a timeout at the payment processor.\n",
            "message_es": "Se recibió la solicitud, pero hubo un tiempo de espera en el procesador de pagos. \n",
            "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center.\n"
         }
      
     
   ],
   "400": [
      {
         "status": "INVALID_REQUEST",
         "reason": "MISSING_FIELD",
         "message": "The request is missing one or more fields.\n",
         "message_es": "A la solicitud le faltan uno o más campos \n",
         "action": "See the reply fields statusInformation.details[] for which fields are missing. Resend the request with the\ncorrect information.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_DATA",
         "message": "One or more fields in the request contains invalid data.\n",
         "message_es": "Uno o más campos de la solicitud contienen datos no válidos.\n",
         "action": "See the reply fields statusInformation.details[] for which fields are invalid. Resend the request with the\ncorrect information.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "DUPLICATE_REQUEST",
         "message": "The merchantReferenceCode sent with this authorization request matches the merchantReferenceCode of another\nauthorization request that you sent in the last 15 minutes.\n",
         "message_es": "El mercantilReferenceCode enviado con esta solicitud de autorización coincide con el mercantilReferenceCode de otra \n solicitud de autorización que envió en los últimos 15 minutos.\n",
         "action": "Resend the request with a unique merchantReferenceCode value.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "EXCEEDS_AUTH_AMOUNT",
         "message": "The requested amount exceeds the originally authorized amount. Occurs, for example, if you try to\ncapture an amount larger than the original authorization amount.\n",
         "message_es": "El monto solicitado excede el monto autorizado originalmente. Ocurre, por ejemplo, si intenta\ncapturar una cantidad mayor que la cantidad de autorización origina.\n",
         "action": "Issue a new authorization and capture request for the new amount.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "AUTH_ALREADY_REVERSED",
         "message": "The authorization has already been reversed.\n",
         "message_es": "La autorización ya ha sido revocada.\n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "TRANSACTION_ALREADY_SETTLED",
         "message": "The transaction has already been settled.\n",
         "message_es": "La transacción ya ha sido liquidada.\n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_AMOUNT",
         "message": "The requested transaction amount must match the previous transaction amount.\n",
         "message_es": "El monto de la transacción solicitada debe coincidir con el monto de la transacción anterior.\n",
         "action": "Correct the amount and resend the request.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_PAYMENT_ID",
         "message": "The referenced request id is invalid for all follow-on transactions.\n",
         "message_es": "La identificación de la solicitud a la que se hace referencia no es válida para todas las transacciones de seguimiento.\n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "MISSING_AUTH",
         "message": "You requested a capture, but there is no corresponding, unused authorization record. Occurs if there was not a\npreviously successful authorization request or if the previously successful authorization has already been used\nin another capture request.\n",
         "message_es": "CAPTURE-Solicitó una captura, pero no hay ningún registro de autorización no utilizado correspondiente. Ocurre si no hubo una \n solicitud de autorización previamente exitosa o si la autorización previamente exitosa ya se usó \n en otra solicitud de captura. \n",
         "action": "Request a new authorization, and if successful, proceed with the capture.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "TRANSACTION_ALREADY_REVERSED_OR_SETTLED",
         "message": "The transaction has already been settled or reversed.\n",
         "message_es": "La transacción ya ha sido liquidada o revertida.\n",
         "action": "No action required.\n"
      }
   ],
   
   "502": [
      
         {
            "status": "SERVER_ERROR",
            "reason": "SYSTEM_ERROR",
            "message": "General system failure.\n",
            "message_es": "Fallo general del sistema. \n",
            "action": "See the documentation for your CyberSource client (SDK) for information about how to handle retries in the case\nof system errors.\n"
         },
         {
            "status": "SERVER_ERROR",
            "reason": "SERVER_TIMEOUT",
            "message": "The request was received but there was a server timeout. This error does not include timeouts between the client\nand the server.\n",
            "message_es": "Se recibió la solicitud pero se agotó el tiempo de espera del servidor. Este error no incluye tiempos de espera entre el cliente\ny el servidor. \n",
            "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Business Center. See the documentation for your CyberSource client (SDK) for information about how to\nhandle retries in the case of system errors.\n"
         },
         {
            "status": "SERVER_ERROR",
            "reason": "SERVICE_TIMEOUT",
            "message": "The request was received, but a service did not finish running in time.\n",
            "message_es": " Se recibió la solicitud, pero un servicio no terminó de ejecutarse a tiempo. \n",
            "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center. See the documentation for your CyberSource client (SDK) for information about\nhow to handle retries in the case of system errors.\n"
         },
         {
            "status": "SERVER_ERROR",
            "reason": "INVALID_OR_MISSING_CONFIG",
            "message": "Error - Invalid or missing merchant configuration\n",
            "message_es": "Error: configuración de comerciante no válida o faltante \n",
            "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
         }
      
   
   ],
 }

 const PAYMENT_DICTIONARY_REVERSAL = {
   "201": [
      {
         "status": "REVERSED",
         "reason": "N/A",
         "message": "Successful transaction.\n",
         "message_es": "Transaccion exitosa. \n",
         "action": "N/A"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "PROCESSOR_TIMEOUT",
         "message": "The request was received, but there was a timeout at the payment processor.\n",
         "message_es": "Se recibió la solicitud, pero hubo un tiempo de espera en el procesador de pagos. \n",
         "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center.\n"
      }
     
   ],
   "400": [
      {
         "status": "INVALID_REQUEST",
         "reason": "MISSING_FIELD",
         "message": "The request is missing one or more fields.\n",
         "message_es": " A la solicitud le faltan uno o más campos. \n",
         "action": "See the reply fields statusInformation.details[] for which fields are missing. Resend the request with the\ncorrect information.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_DATA",
         "message": "One or more fields in the request contains invalid data.\n",
         "message_es": "Uno o más campos de la solicitud contienen datos no válidos\n",
         "action": "See the reply fields statusInformation.details[] for which fields are invalid. Resend the request with the\ncorrect information.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "DUPLICATE_REQUEST",
         "message": "The merchantReferenceCode sent with this authorization request matches the merchantReferenceCode of another\nauthorization request that you sent in the last 15 minutes.\n",
         "message_es": "El código de referencia del comerciante enviado con esta solicitud de autorización coincide con el código de referencia del comerciante de otra\n solicitud de autorización que envió en los últimos 15 minutos. \n",
         "action": "Resend the request with a unique merchantReferenceCode value.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "PROCESSOR_UNAVAILABLE",
         "message": "Processor failure.\n",
         "message_es": "Fallo del procesador.\n",
         "action": "Wait a few minutes and resend the request.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "AUTH_ALREADY_REVERSED",
         "message": "The authorization has already been reversed.\n",
         "message_es": "La autorización ya ha sido revocada. \n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "TRANSACTION_ALREADY_SETTLED",
         "message": "The transaction has already been settled.\n",
         "message_es": "La transacción ya ha sido liquidada.\n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_AMOUNT",
         "message": "The requested transaction amount must match the previous transaction amount.\n",
         "message_es": "El monto de la transacción solicitada debe coincidir con el monto de la transacción anterior.",
         "action": "Correct the amount and resend the request.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "INVALID_PAYMENT_ID",
         "message": "The referenced request id is invalid for all follow-on transactions.\n",
         "message_es": "La identificación de la solicitud a la que se hace referencia no es válida para todas las transacciones de seguimiento.\n",
         "action": "No action required.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "MISSING_AUTH",
         "message": "You requested a capture, but there is no corresponding, unused authorization record. Occurs if there was not a \n previously successful authorization request or if the previously successful authorization has already been used\nin another capture request.\n",
         "message_es": "Reversal-Solicitó una captura, pero no hay ningún registro de autorización no utilizado correspondiente. Ocurre si no hubo una \n solicitud de autorización previamente exitosa o si la autorización anterior exitosa ya se usó\nen otra solicitud de captura. \n",
         "action": "Request a new authorization, and if successful, proceed with the capture.\n"
      },
      {
         "status": "INVALID_REQUEST",
         "reason": "TRANSACTION_ALREADY_REVERSED_OR_SETTLED",
         "message": "The transaction has already been settled or reversed.\n",
         "message_es": "La transacción ya ha sido liquidada o revertida.\n",
         "action": "No action required.\n"
      }
   ],
   
   "502": [
      
      {
         "status": "SERVER_ERROR",
         "reason": "SYSTEM_ERROR",
         "message": "General system failure.\n",
         "message_es": "Fallo general del sistema. \n",
         "action": "See the documentation for your CyberSource client (SDK) for information about how to handle retries in the case\nof system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "SERVER_TIMEOUT",
         "message": "The request was received but there was a server timeout. This error does not include timeouts between the client\nand the server.\n",
         "message_es": "Se recibió la solicitud pero se agotó el tiempo de espera del servidor. Este error no incluye tiempos de espera entre el cliente\ny el servidor. \n",
         "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Business Center. See the documentation for your CyberSource client (SDK) for information about how to\nhandle retries in the case of system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "SERVICE_TIMEOUT",
         "message": "The request was received, but a service did not finish running in time.\n",
         "message_es": "Se recibió la solicitud, pero un servicio no terminó de ejecutarse a tiempo.\n",
         "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center. See the documentation for your CyberSource client (SDK) for information about\nhow to handle retries in the case of system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "INVALID_OR_MISSING_CONFIG",
         "message": "Error - Invalid or missing merchant configuration\n",
         "message_es": "Error: configuración de comerciante no válida o faltante \n",
         "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
      }
   
   ],
 }

 const PAYMENT_DICTIONARY_REFUND = {
   "201": [   {
      "status": "PENDING",
      "reason": "N/A",
      "message": "Successful transaction.\n",
      "message_es": "Transaccion exitosa. \n",
       "action": "N/A"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "INVALID_MERCHANT_CONFIGURATION",
      "message": "There is a problem with your CyberSource merchant configuration.\n",
      "message_es": " Hay un problema con la configuración de comerciante de CyberSource. \n",
      "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
   },
   {
      "status": "SERVER_ERROR",
      "reason": "PROCESSOR_TIMEOUT",
      "message": "The request was received, but there was a timeout at the payment processor.\n",
      "message_es": "Se recibió la solicitud, pero hubo un tiempo de espera en el procesador de pagos\n",
      "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center.\n"
   } ],
   "400": [   {
      "status": "INVALID_REQUEST",
      "reason": "MISSING_FIELD",
      "message": "The request is missing one or more fields.\n",
      "message_es": "A la solicitud le faltan uno o más campos.\n",
      "action": "See the reply fields statusInformation.details[] for which fields are missing. Resend the request with the\ncorrect information.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "INVALID_DATA",
      "message": "One or more fields in the request contains invalid data.\n",
      "message_es": "Uno o más campos de la solicitud contienen datos no válidos. \n",
      "action": "See the reply fields statusInformation.details[] for which fields are invalid. Resend the request with the\ncorrect information.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "DUPLICATE_REQUEST",
      "message": "The merchantReferenceCode sent with this authorization request matches the merchantReferenceCode of another\nauthorization request that you sent in the last 15 minutes.\n",
      "message_es": " El código de referencia del comerciante enviado con esta solicitud de autorización coincide con el código de referencia del comerciante de otra\nsolicitud de autorización que envió en los últimos 15 minutos.\n",
      "action": "Resend the request with a unique merchantReferenceCode value.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "INVALID_CARD",
      "message": "Invalid account number.\n",
      "message_es": "Número de cuenta no válido. \n",
      "action": "Request a different card or other form of payment.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "INVALID_AMOUNT",
      "message": "The requested transaction amount must match the previous transaction amount.\n",
      "message_es": "El monto de la transacción solicitada debe coincidir con el monto de la transacción anterior.",
      "action": "Correct the amount and resend the request.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "CAPTURE_ALREADY_VOIDED",
      "message": "You requested a credit for a capture that was previously voided.\n",
      "message_es": "Solicitó un crédito por una captura que se anuló anteriormente. \n",
      "action": "No action required.\n"
   },
   {
      "status": "INVALID_REQUEST",
      "reason": "ACCOUNT_NOT_ALLOWED_CREDIT",
      "message": "Account is prohibited from processing stand-alone refunds.\n",
      "message_es": "La cuenta tiene prohibido procesar reembolsos independientes.",
      "action": "Submit a follow-on credit by including a request ID in the credit request. A follow-on credit must be requested\nwithin 60 days of the authorization.To process stand-alone credits, contact your CyberSource account\nrepresentative to find out if your processor supports stand-alone credits.\n"
   }],
   
   "502": [
      {
         "status": "SERVER_ERROR",
         "reason": "SYSTEM_ERROR",
         "message": "General system failure.\n",
         "message_es": "Fallo general del sistema. \n",
         "action": "See the documentation for your CyberSource client (SDK) for information about how to handle retries in the case\nof system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "SERVER_TIMEOUT",
         "message": "The request was received but there was a server timeout. This error does not include timeouts between the client\nand the server.\n",
         "message_es": "Se recibió la solicitud pero se agotó el tiempo de espera del servidor. Este error no incluye tiempos de espera entre el cliente\ny el servidor. \n",
         "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Business Center. See the documentation for your CyberSource client (SDK) for information about how to\nhandle retries in the case of system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "SERVICE_TIMEOUT",
         "message": "The request was received, but a service did not finish running in time.\n",
         "message_es": "Se recibió la solicitud, pero un servicio no terminó de ejecutarse a tiempo.\n",
         "action": "To avoid duplicating the transaction, do not resend the request until you have reviewed the transaction status\nin the Enterprise Business Center. See the documentation for your CyberSource client (SDK) for information about\nhow to handle retries in the case of system errors.\n"
      },
      {
         "status": "SERVER_ERROR",
         "reason": "INVALID_OR_MISSING_CONFIG",
         "message": "Error - Invalid or missing merchant configuration\n",
         "message_es": "Error: configuración de comerciante no válida o faltante \n",
         "action": "Do not resend the request. Contact Customer Support to correct the configuration problem.\n"
      }

   ]
      
   
 
 }

 let LINK_TRANSACTION_HEADERS = {
   ACCEPT: [
       new HeaderGrid('ID','bw_cybersource_invoice_payment_id'),
       new HeaderGrid('ID Transaccion','transaction_id'),
       new HeaderGrid('Sucursal', 'branches_name'),
       new HeaderGrid('Fecha de Expiracion','req_card_expiry_date'),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_type_name', img_ext: 'png'}),
       new HeaderGrid('Fecha ','auth_date', { type: 'date-time' }),
       new HeaderGrid('Estatus','decision'),
       new HeaderGrid('Importe', 'auth_amount',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Comision', 'commission',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('IVA Comision', 'iva_commission',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Pago Neto', 'total_payment',{type: 'currency', function: 'SUM'})
   ],
   DECLINE: [
       new HeaderGrid('ID','bw_cybersource_invoice_payment_id'),
       new HeaderGrid('Fecha de Expiracion','req_card_expiry_date'),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_type_name', img_ext: 'png'}),
       new HeaderGrid('Fecha de Importe ','created', { type: 'date'}),
       new HeaderGrid('Importe', 'auth_amount',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Estatus','decision'),
       new HeaderGrid('Razon de Decline','message'),
       ]
}

let PHYSICAL_TRANSACTION_HEADERS = {
   '/payment/sale': 
   [
       new HeaderGrid('Fecha', 'trxDate'), 
       new HeaderGrid('ID', 'trxOriginalNumber'), 
       new HeaderGrid('Hora', 'trxTime', { type: "time" }), 
       new HeaderGrid('Sucursal', 'branch_name'),
       new HeaderGrid('Terminal', 'trxDevice'), 
       new HeaderGrid('Usuario', 'trxUser'),  
       new HeaderGrid('Referencia', 'trxReference'),
       new HeaderGrid('Metodo de Pago', 'trxPaymentMode'),
       new HeaderGrid('Banco', 'trxCardBank', {img: true, img_field: 'trxCardBank', img_ext: 'png', img_width: '3.5rem', img_show_text: false}),
       new HeaderGrid('Marca', 'trxCardBrand',  {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
       new HeaderGrid('Tarjeta', 'trxCard', {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
       new HeaderGrid('Tipo', 'txtCardInstrumentMode', {formula: 'concat', expression: 'trxCardInstrument + { } + trxPaymentMode'}),
       new HeaderGrid('Importe', 'trxAmount', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Comision', 'trxComission', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('IVA Comision', 'trxComissionIVA', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Pago Neto', 'trxTotalPayment', { type: 'currency', function: 'SUM'} ),
   ],
   '/payment/refund':
   [
      new HeaderGrid('Fecha', 'trxDate'), 
      new HeaderGrid('ID', 'trxOriginalNumber'), 
      new HeaderGrid('Hora', 'trxTime'), 
      new HeaderGrid('Sucursal', 'branch_name'), 
      new HeaderGrid('Terminal', 'trxDevice'), 
      new HeaderGrid('Usuario', 'trxUser'),  
      new HeaderGrid('Referencia', 'trxReference'),
      new HeaderGrid('Metodo de Pago', 'trxPaymentMode'),
      new HeaderGrid('Banco', 'trxCardBank'),
      new HeaderGrid('Marca', 'trxCardBrand'),
      new HeaderGrid('Tarjeta', 'trxCard', {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
     new HeaderGrid('Tipo', 'txtCardInstrumentMode', {formula: 'concat', expression: 'trxCardInstrument + { } + trxPaymentMode'}),
      new HeaderGrid('Importe', 'trxAmount', { type: 'currency', function: 'SUM'} ),
  ],
}

let LINK_TRANSACTION_HEADERS2 = {
   ACCEPT: [
       new HeaderGrid('ID','bw_cybersource_invoice_payment_id'),
       new HeaderGrid('ID Transaccion','transaction_id'),
       new HeaderGrid('Sucursal', 'branches_name'),
       new HeaderGrid('Fecha de Expiracion','req_card_expiry_date'),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_type_name', img_ext: 'png'}),
       new HeaderGrid('Meses','installment_count'), 
       new HeaderGrid('Fecha ','auth_date', { type: 'date-time' }),
       new HeaderGrid('Estatus','decision'),
       new HeaderGrid('Importe', 'auth_amount',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Comision', 'commission',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('IVA Comision', 'iva_commission',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Pago Neto', 'total_payment',{type: 'currency', function: 'SUM'})
   ],
   DECLINE: [
       new HeaderGrid('ID','bw_cybersource_invoice_payment_id'),
       new HeaderGrid('Fecha de Expiracion','req_card_expiry_date'),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_type_name', img_ext: 'png'}),
       new HeaderGrid('Meses','installment_count'), 
       new HeaderGrid('Fecha de Importe ','created', { type: 'date'}),
       new HeaderGrid('Importe', 'auth_amount',{type: 'currency', function: 'SUM'}),
       new HeaderGrid('Estatus','decision'),
       new HeaderGrid('Razon de Decline','message'),
       ]
}

let PHYSICAL_TRANSACTION_HEADERS2 = {
   '/payment/sale': 
   [
       new HeaderGrid('Fecha', 'trxDate'), 
       new HeaderGrid('ID', 'trxOriginalNumber'), 
       new HeaderGrid('Hora', 'trxTime', { type: "time" }), 
       new HeaderGrid('Sucursal', 'branch_name'),
       new HeaderGrid('Terminal', 'trxDevice'),  
       new HeaderGrid('Referencia', 'trxReference'),
       new HeaderGrid('Metodo de Pago', 'trxPaymentMode'),

       new HeaderGrid('Banco', 'trxCardBank', {img: true, img_field: 'trxCardBank', img_ext: 'png', img_width: '3.5rem', img_show_text: false}),
       new HeaderGrid('Marca', 'trxCardBrand',  {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
       new HeaderGrid('Tarjeta', 'trxCard', {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
     new HeaderGrid('Tipo', 'txtCardInstrumentMode', {formula: 'concat', expression: 'trxCardInstrument + { } + trxPaymentMode'}),
       new HeaderGrid('Importe', 'trxAmount', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Comision', 'trxComission', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('IVA Comision', 'trxComissionIVA', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Pago Neto', 'trxTotalPayment', { type: 'currency', function: 'SUM'} ),
   ],
   '/payment/refund':
   [
      new HeaderGrid('Fecha', 'trxDate'), 
      new HeaderGrid('ID', 'trxOriginalNumber'), 
      new HeaderGrid('Hora', 'trxTime'), 
      new HeaderGrid('Sucursal', 'branch_name'), 
      new HeaderGrid('Terminal', 'trxDevice'), 
      new HeaderGrid('Referencia', 'trxReference'),
      new HeaderGrid('Metodo de Pago', 'trxPaymentMode'),
    
      new HeaderGrid('Banco', 'trxCardBank'),
      new HeaderGrid('Marca', 'trxCardBrand'),
      new HeaderGrid('Tarjeta', 'trxCard', {img: true, img_field: 'trxCardBrand', img_ext: 'png'}),
     new HeaderGrid('Tipo', 'txtCardInstrumentMode', {formula: 'concat', expression: 'trxCardInstrument + { } + trxPaymentMode'}),
      new HeaderGrid('Importe', 'trxAmount', { type: 'currency', function: 'SUM'} ),
  ],
}


let VIRTUAL_TRANSACTION_HEADERS = {
   AUTHORIZED: [
       new HeaderGrid('ID', 'id'), 
       new HeaderGrid('Sucursal', 'branch'),
       new HeaderGrid('Hora', 'submitTimeUtc', {type: 'time'}),
       new HeaderGrid('Concepto','concept'),
       new HeaderGrid('Tipo','type'),
       new HeaderGrid('Meses','installment_count'), 
       new HeaderGrid('Fecha', 'date'),
       new HeaderGrid('Tipo Tarjeta', 'card_type', {type: 'upper'}),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_branch_text', img_ext: 'png'}),
       new HeaderGrid('Importe', 'authorized_amount', {type: 'currency', function: 'SUM'}),
       new HeaderGrid('Comision', 'comission', {type: 'currency', function: 'SUM'}),
       new HeaderGrid('IVA Comision', 'comission_iva', {type: 'currency', function: 'SUM'}),
       new HeaderGrid('Pago Neto', 'total_payment', {type: 'currency', function: 'SUM'}),
       new HeaderGrid('Estatus', 'status'),
       new HeaderGrid('Capturado', 'is_captured', {type: 'boolean'})
       
   ],
   DECLINED: [
       new HeaderGrid('ID', 'id'), 
       new HeaderGrid('Sucursal', 'branch'),
       new HeaderGrid('Fecha', 'created', {type: 'date-time'}),
       new HeaderGrid('Concepto','concept'),
       new HeaderGrid('Tipo','type'),
       new HeaderGrid('Meses','installment_count'), 
       new HeaderGrid('Tipo Tarjeta', 'card_type', {type: 'upper'}),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'card_branch_text', img_ext: 'png'}),
       new HeaderGrid('Razon', 'error_information_reason'),
       new HeaderGrid('Error', 'error_information_message')
   ]
}

let ALL_TRANSACTION_HEADERS = {
   '/payment/sale': 
   [
       new HeaderGrid('Fecha', 'date'), 
       new HeaderGrid('ID', 'id'), 
      new HeaderGrid('#Operacion', 'operationnum'),  
       new HeaderGrid('#Aprobacion', 'authnum'),
       new HeaderGrid('Hora', 'trxtime', {type: 'time'}), 
       new HeaderGrid('Sucursal', 'branches_name'),
      //  new HeaderGrid('Terminal', 'trxDevice'),  
      //  new HeaderGrid('Referencia', 'trxReference'),
       new HeaderGrid('Metodo de Pago', 'req_card_type'),
       new HeaderGrid('Meses','installment_count'), 
      //  new HeaderGrid('Banco', 'trxCardBank', {img: true, img_field: 'trxCardBank', img_ext: 'png', img_width: '3.5rem', img_show_text: false}),
        new HeaderGrid('Marca', 'cardbrand',  {img: true, img_field: 'cardbrand', img_ext: 'png'}),
       new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'cardbrand', img_ext: 'png'}),

       new HeaderGrid('Importe', 'auth_amount', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Comision', 'comission', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('IVA Comision', 'comission_iva', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Pago Neto', 'total_payment', { type: 'currency', function: 'SUM'} ),
       new HeaderGrid('Origen','origin'),
       new HeaderGrid('Procesador','processor'),
   ],



   // amount = sales.TrxAmount,
   // branch_name = sales.branch_name,
   // auth_time = sales.TrxDate,
   // auth_amount = sales.TrxAmount,
   // id = sales.id,
   // req_card_type = sales.TrxPaymentMode,
   // auth_date = sales.TrxDate != null ? sales.TrxDate : "",
   // sales.TrxTime,
   // card_number = sales.TrxCard,
   // invalid_fields = "",
   // message = "",
   // decision = "",
   // rate = "",
   // comission = sales.TrxAmount * (sales.TrxRate + sales.TrxSurcharge),
   // comissionIVA = sales.TrxAmount * (sales.TrxRate + sales.TrxSurcharge) * .16M,
   // totalPayment
   '/payment/refund':
   [
      new HeaderGrid('Fecha', 'date'), 
      new HeaderGrid('ID', 'id'), 
      new HeaderGrid('Hora', 'TrxTime', {type: 'time'}), 
      new HeaderGrid('Sucursal', 'branches_name'),
      // new HeaderGrid('Terminal', 'trxDevice'),  
      // new HeaderGrid('Referencia', 'trxReference'),
      new HeaderGrid('Metodo de Pago', 'req_card_type'),
      // new HeaderGrid('Banco', 'trxCardBank', {img: true, img_field: 'trxCardBank', img_ext: 'png', img_width: '3.5rem', img_show_text: false}),
       new HeaderGrid('Marca', 'cardbrand',  {img: true, img_field: 'cardbrand', img_ext: 'png'}),
      new HeaderGrid('Tarjeta', 'card_number', {img: true, img_field: 'cardbrand', img_ext: 'png'}),

      new HeaderGrid('Importe', 'auth_amount', { type: 'currency', function: 'SUM'} ),
      new HeaderGrid('Comision', 'comission', { type: 'currency', function: 'SUM'} ),
      new HeaderGrid('IVA Comision', 'comission_iva', { type: 'currency', function: 'SUM'} ),
      new HeaderGrid('Pago Neto', 'total_payment', { type: 'currency', function: 'SUM'} ),
      new HeaderGrid('Origen','origin'),
  ],
}

let REPORT_SALDOS = {
   ACCEPT: [
      new HeaderGrid('Almacen','strdescriptionwarehouse'),
      new HeaderGrid('SubAlmacen','strdescriptionsubwarehouse'),
      new HeaderGrid('# Movimiento','id_movementwarehouse'),
      new HeaderGrid('Tipo de Movimiento','movement'),
      new HeaderGrid('# Articulo','id_article'),
      new HeaderGrid('Articulo','strdescriptionarticle'),
      new HeaderGrid('Precio Unitario', 'coust',{ type:'currency'}),
      new HeaderGrid('Fecha de Movimiento','created', { type: 'date'}),
      new HeaderGrid('Qty Inicial', 'qtyinicial'),
      new HeaderGrid('Costo Inicial', 'coustinicial' ,{ type:'currency'}),
         //    new HeaderGrid('Cantidad Aplicada', 'quantity'),
      new HeaderGrid('Cantidad Aplicada', 'final_cantidad' , { type: 'number', function: 'SUM', formula: 'evaluate', expression: '(qtyfinal -qtyinicial) '}),
      new HeaderGrid('Monto Aplicado', 'final_total' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: '(coustfinal -coustinicial) '}),
      new HeaderGrid('Qty Final', 'qtyfinal'),
      new HeaderGrid('Costo Final', 'coustfinal' ,{ type:'currency'}),
   ],
}

   let REPORT_KARDEX ={
      ACCEPT: [ 
 new HeaderGrid('Almacen','strdescriptionwarehouse'),
new HeaderGrid('SubAlmacen','strdescriptionsubwarehouse'),
new HeaderGrid('Fecha de Movimiento','created', { type: 'date'}),
new HeaderGrid('# Movimiento','id_movementwarehouse'),
new HeaderGrid('Tipo de Movimiento','strdescriptionmovementtype'),
new HeaderGrid('# Articulo','id_article'),
 new HeaderGrid('Articulo','strdescriptionarticle'),
 new HeaderGrid('ENTRADA','qtyEntrada',{ type:'decimal'}),
 new HeaderGrid('ENTRADA $','coustEntrada',{ type:'currency'}),
 new HeaderGrid('SALIDA','qtySalida',{ type:'decimal'}),
 new HeaderGrid('SALIDA $','coustSalida',{ type:'currency'}),
new HeaderGrid('Cantidad', 'quantity',{ type:'decimal'}),
new HeaderGrid('Precio Unitario', 'coust',{ type:'currency'}),
new HeaderGrid('Total', 'final_total' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: '(coust * quantity) '}),

  new HeaderGrid('Usuario','created_by'),
],
   }

   let REPORT_INVENTARIO ={
      ACCEPT: [new HeaderGrid('Almacen', 'strwarehouse'), new HeaderGrid('SubAlmacen', 'strsubwarehouse'), new HeaderGrid('SKU', 'id_article'), new HeaderGrid('Nombre', 'strarticle'), new HeaderGrid('Cantidad', 'quantity',{ type:'decimal'})],
   }

   let REPORT_COSTO_PROMEDIO ={
      ACCEPT: [ new HeaderGrid('SKU', 'id_article'), new HeaderGrid('Nombre', 'strarticle'), new HeaderGrid('Cantidad', 'quantity',{ type:'decimal'}) , new HeaderGrid('Costo', 'coust',{ type:'currency'})],
   }

   let REPORT_MAX_MIN ={
      ACCEPT: [new HeaderGrid('Almacen', 'strwarehouse'), new HeaderGrid('SubAlmacen', 'strsubwarehouse'), new HeaderGrid('SKU', 'barcode'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('Cantidad', 'quantity',{ type:'decimal'}), new HeaderGrid('Maximo', 'max',{ type:'decimal'}), new HeaderGrid('Minimo', 'min',{ type:'decimal'})],
      ALMACEN: [new HeaderGrid('Almacen', 'strwarehouse'), new HeaderGrid('SKU', 'barcode'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('Cantidad', 'quantity',{ type:'decimal'}), new HeaderGrid('Maximo', 'max',{ type:'decimal'}), new HeaderGrid('Minimo', 'min',{ type:'decimal'})]
 
   }

   





export { 
   REPORT_MAX_MIN ,
   REPORT_COSTO_PROMEDIO,
   REPORT_INVENTARIO,
   BW_ERROR, 
   REPORT_KARDEX,
   REPORT_SALDOS,
   PAYMENT_DICTIONARY,
   PAYMENT_DICTIONARY_CAPTURE,
   PAYMENT_DICTIONARY_REVERSAL,
   PAYMENT_DICTIONARY_REFUND,
   LINK_TRANSACTION_HEADERS2,
   PHYSICAL_TRANSACTION_HEADERS2,
   LINK_TRANSACTION_HEADERS,
   PHYSICAL_TRANSACTION_HEADERS,
   VIRTUAL_TRANSACTION_HEADERS,
   ALL_TRANSACTION_HEADERS
}